<template>
  <div class="modal fade" id="modal-work-edit">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Work Log</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Technician</label>
                <select v-model="form.user_id"
                        :class="['form-control', {'is-invalid': errorFor('start')}]">>
                  <option value="">Select Technician</option>
                  <option :value="technician.id"
                          v-for="technician in technicians"
                          :key="'technician_'+technician.id">{{ technician.name }}</option>
                </select>
                <v-errors :errors="errorFor('start')" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Start</label>
                <input type="datetime-local"
                       v-model="form.start"
                       :class="['form-control', {'is-invalid': errorFor('start')}]">
                <v-errors :errors="errorFor('start')" />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>End</label>
                <input type="datetime-local"
                       v-model="form.end"
                       :class="['form-control', {'is-invalid': errorFor('end')}]">
                <v-errors :errors="errorFor('end')" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-dark" data-dismiss="modal">Close</button>
          <button type="button"
                  class="btn btn-primary"
                  @click.prevent="submit">Save changes</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      technicians: [],
      log_id: '',
      form: {
        user_id: '',
        start: '',
        end: ''
      },
    }
  },
  created() {
    this.loadTechnicians();
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-work-edit'));
  },
  methods: {
    show(log = null) {
      if (log) {
        this.log_id = log.id;

        this.form = {
          user_id: log.user_id,
          start: log.start_raw,
          end: log.end_raw,
        };
      } else {
        this.log_id = '';
        this.form = {
          user_id: '',
          start: window.moment().format('YYYY-MM-DD')+'T08:00',
          end: window.moment().format('YYYY-MM-DD')+'T16:00',
        };
      }

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadTechnicians() {
      this.axios.get('/technicians')
        .then(res => {
          this.technicians = res.data.data;
        })
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      if (this.log_id) {
        this.axios.patch('/work-log/' + this.log_id, this.form)
          .then(() => {
            this.hide();
            this.$emit('updated')
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.axios.post('/work-log', this.form)
          .then(() => {
            this.hide();
            this.$emit('updated')
          })
          .catch((err) => {
            this.allErrors = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
}
</script>