<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0">Work</h1>
        </div><!-- /.col -->
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body">
              <table-component
                :columns="columns"
                :rows="logs"
                :sortable="sortable"
                @sorting="sorting"
                :pagination="pagination"
                @search="search"
                @pageChanged="pageChanged">

                <template v-slot:top>
                  <div class="row">
                    <div class="col-8">
                      <button class="btn btn-success"
                              @click="$refs.workEditModal.show()">
                        <i class="fas fa-clock"></i> Add Log
                      </button>
                    </div>
                    <div class="col-4">
                      <select class="form-control"
                              @change="changeTechnicianHandler"
                              v-model="queryParams.technician_id">
                        <option value="">All Technician</option>
                        <option :value="technician.id"
                                v-for="technician in technicians"
                                :key="'tech_'+technician.id">{{ technician.name }}</option>
                      </select>
                    </div>
                  </div>
                </template>

                <template v-slot:action="{ row }">
                  <a type="button"
                     v-if="row.end"
                     class="btn btn-sm btn-success mr-1"
                     title="Scorecard"
                     @click.prevent="$refs.scModal.show(row)">
                    <i class="fas fa-star"></i>
                  </a>

                  <a type="button"
                     v-if="row.end"
                     @click="$refs.workEditModal.show(row)"
                     class="btn btn-sm btn-warning mr-1">
                    <i class="fas fa-edit"></i>
                  </a>

                  <a type="button"
                     v-if="!row.end"
                     class="btn btn-sm btn-danger"
                     @click.prevent="end(row)">
                    <i class="fas fa-stop-circle"></i>
                  </a>

                  <a type="button"
                     v-if="row.end"
                     class="btn btn-sm btn-danger"
                     @click.prevent="deleteData(row)">
                    <i class="fas fa-trash"></i>
                  </a>
                </template>
              </table-component>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <work-edit-modal @updated="loadData"
                   ref="workEditModal" />

  <scorecard-modal @updated="loadData"
                   ref="scModal" />
</template>

<script>
import TableComponent from "@/components/TableComponent";
import WorkEditModal from "./WorkEditModal.vue";
import ScorecardModal from "./ScorecardModal.vue";
export default {
  components: {TableComponent, WorkEditModal, ScorecardModal},
  data() {
    return {
      logs: [],
      workRunning: false,
      technicians: [],
      queryParams: {
        technician_id: '',
        search: '',
        search_columns: '',
        page: 1,
        sort: 'start',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'start',
        sort: 'desc'
      },
      columns: [
        {
          label: "Technician",
          field: "user.name",
          sortable: true,
          searchable: true,
        },
        {
          label: "Start",
          field: "start",
          sortable: true,
          searchable: false,
        },
        {
          label: "End",
          field: "end",
          sortable: true,
          searchable: false,
        },
        {
          label: "Worked",
          field: "worked",
          sortable: false,
          searchable: false,
        },
        {
          label: "Personal",
          field: "personal",
          sortable: false,
          searchable: false,
        },
        {
          label: "Client",
          field: "client",
          sortable: false,
          searchable: false,
        },
        {
          label: "Communication",
          field: "communication",
          sortable: false,
          searchable: false,
        },
        {
          label: "Power Score",
          field: "power_score",
          sortable: false,
          searchable: false,
        },
        {
          label: "Comment",
          field: "comment",
          sortable: false,
          searchable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
          searchable: false,
          slot: true,
          class: ['text-right']
        },
      ],
      pagination: null,
    }
  },
  created() {
    this.loadData();

    this.axios.get('/technicians')
        .then((response) => {
          this.technicians = response.data.data
        })
  },
  methods: {
    changeTechnicianHandler() {
      this.queryParams.page = 1;
      this.loadData();
    },
    loadData() {
      this.logs = [];

      this.axios.get('/work-log', {params: this.queryParams})
        .then((response) => {
          this.logs = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    deleteData(row) {
      this.$swal({
        title: 'Are you sure want to delete?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/work-log/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              'Deleted',
              'Log has been deleted!',
              'success'
            )
          });
        }
      });
    },
    end(row) {
      this.$swal({
        title: 'Are you sure want to end?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/work-log/stop/' + row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              'Checked Out',
              'Log has been checked ended!',
              'success'
            )
          });
        }
      });
    },
  }
}
</script>