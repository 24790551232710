<template>
  <div class="modal fade" id="modal-work-sc">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Scoreboard</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <table class="table table-sm table-bordered">
            <tr>
              <th class="text-center text-primary" colspan="2">{{ name }}</th>
            </tr>
            <tr>
              <th>Personal</th>
              <td class="text-center text-warning">
                <i class="fas fa-star"
                   v-for="r in form.personal"
                   :key="'personal_'+r"
                   @click="changeSc('personal', r)"></i>
                <i class="far fa-star"
                   v-for="r in 10-form.personal"
                   :key="'personal_'+r"
                   @click="changeSc('personal',  form.personal + r)"></i>
              </td>
            </tr>
            <tr>
              <th>Client</th>
              <td class="text-center text-warning">
                <i class="fas fa-star"
                   v-for="r in form.client"
                   :key="'client_'+r"
                   @click="changeSc('client', r)"></i>
                <i class="far fa-star"
                   v-for="r in 10-form.client"
                   :key="'client_'+r"
                   @click="changeSc('client',  form.client + r)"></i>
              </td>
            </tr>
            <tr>
              <th>Communication</th>
              <td class="text-center text-warning">
                <i class="fas fa-star"
                   v-for="r in form.communication"
                   :key="'communication_'+r"
                   @click="changeSc('communication', r)"></i>
                <i class="far fa-star"
                   v-for="r in 10-form.communication"
                   :key="'communication_'+r"
                   @click="changeSc('communication',  form.communication + r)"></i>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <input type="text"
                       class="form-control form-control-sm modal-sc-comment"
                       v-model="form.comment"
                       placeholder="Comment">
              </td>
            </tr>
          </table>
        </div>
        <div class="modal-footer justify-content-between">
          <button type="button" class="btn btn-dark" data-dismiss="modal">Close</button>
          <button type="button"
                  class="btn btn-primary"
                  @click.prevent="submit">Save changes</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      log_id: '',
      name: '',
      form: {
        personal: 0,
        client: 0,
        communication: 0,
        comment: '',
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('modal-work-sc'));
  },
  methods: {
    show(log) {
      this.log_id = log.id;
      this.name = log.user.name;
      this.form.personal = log.personal;
      this.form.client = log.client;
      this.form.communication = log.communication;
      this.form.comment = log.comment;

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    changeSc(type, score) {
      this.form[type] = score;
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/work-log/sc/' + this.log_id, this.form)
        .then(() => {
          this.hide();
          this.$emit('updated')
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>